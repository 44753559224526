<template>
  <div class="contact">
    <div class="common" :style="{ height: height + 'px' }">
      <Header class="header" />
      <div class="contact-content">
        <h3>您可以通过以下方式联系我们</h3>
        <div>
          <ul>
            <li>
              <div class="li_l">
                <img src="@/assets/img/email.png" alt="" />
              </div>
              <div class="li_r">
                <p class="p1">米圈官方邮箱:</p>
                <p class="p2">370588744@qq.com</p>
              </div>
            </li>
            <li>
              <!-- <div class="li_l">
                <img src="@/assets/img/user.png" alt="" />
              </div>
              <div class="li_r">
                <p class="p1">商务经理米圈号:</p>
                <p class="p2">wanyoung</p>
              </div> -->
            </li>
            <li style="position:relative;">
              <!-- <div class="li_l">
                <img src="@/assets/img/san.png" alt="" />
              </div>
              <div class="li_r">
                <p class="p1">请打开聊天扫一扫:</p>
                <p class="p2">扫描二维码联系米圈客户经理</p>
              </div>
              <div class="qrcode">
                <img src="@/assets/img/user_wechat.png" alt="" />
              </div> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Bottom class="bottom" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Bottom from "@/components/Bottom";
export default {
  name: "Home",
  data() {
    return {
      height: 0
    };
  },
  components: {
    Header,
    Bottom
  },
  created() {
    this.height = document.documentElement.clientHeight - 102;
  }
};
</script>
<style lang="stylus" scoped>
.bottom
  border-top 1px solid #ccc
.header
  border-bottom 1px solid #ccc
.contact-content
  width 1180px
  margin 0 auto
  h3
    text-align left
    text-indent 85px
    margin 150px 0 60px 0
  ul
    display flex
    justify-content center
    align-items center
    li
      flex 1
      display flex
      justify-content center
      font-size 18px
      img
        margin-right 10px
      .li_r
        text-align left
        .p1
          color #666
          margin-bottom 10px
        .p2
          color #333
  .qrcode
    position absolute
    width 242px
    height 257px
    top 65px
    background url('../assets/img/model.png') center center
    background-size 100% 100%
    img
      width 220px
      height 220px
      margin 25px auto
</style>
