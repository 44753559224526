<template>
  <div class="home">
    <div class="common" :style="{ height: height + 'px' }">
      <!-- <Header class="header" /> -->
      <div class="home-content">
        <div class="home-content-c">
          <!-- <img src="@/assets/img/index.png" alt="" /> -->
          <div style="font-size: 40px;">因热爱而专注，因专注而专业</div>
          <br />
          <!-- <div>承接小程序、公众号、Web 网站、手机 App 等软件开发</div> -->
        </div>
        <!-- <div class="loadBtn">
          <img src="@/assets/img/btn.png" alt="" />
          <div class="loadImg">
            <img src="@/assets/img/download-qr.png" alt="" />
            <p style="color:#999">扫描二维码下载米圈</p>
          </div>
        </div> -->
      </div>
    </div>
    <Bottom class="bottom" />
  </div>
</template>

<script>
// import Header from "@/components/Header";
import Bottom from "@/components/Bottom";
export default {
  name: "Home",
  data() {
    return {
      height: 0
    };
  },
  components: {
    // Header,
    Bottom
  },
  created() {
    this.height = document.documentElement.clientHeight - 102;
  }
};
</script>
<style scoped lang="stylus">
.bottom
  border-top 1px solid transparent
.header
  border-bottom 1px solid transparent
.home
  .common
    display flex
    flex-direction column
    background url("../assets/img/bj.png") center center
    background-size 100% 100%
    .home-content
      flex 1
      width 1180px
      margin 0 auto
      position relative
      .loadBtn
        position absolute
        left 200px
        top 41%
        width 180px
        >img
          width 100%
      .loadImg
          // width 242px
          // height 257px
          width 217px
          height 231px
          margin-left -16px
          background url('../assets/img/model.png') center center
          background-size 100% 100%
          img
            width 75%
            margin-top 30px
      .home-content-c
        display flex
        flex-direction: column
        height 100%
        justify-content center
        align-items center
        img
          width 70%
</style>
